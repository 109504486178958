import React from 'react';

import { isNativeMobileApp } from 'src/explore/services/mobile';

const MobileTheme = React.lazy(() => import( './MobileTheme' ));

const ThemeSelector = () => (
  <>
    <React.Suspense fallback={<></>}>{isNativeMobileApp() && <MobileTheme />}</React.Suspense>
  </>
);

export default ThemeSelector;
